const data = [
  {
    coverImage: "joan_baixas.jpg",
    coverText: "joan_baixas.svg",
    menu: [
      { title: "Il rapporto con Mirò", video: "1.mp4" },
      { title: "Option 2", video: "2.mp4" },
      { title: "Option 3", video: "3.mp4" },
      { title: "Option 4", video: "4.mp4" },
      { title: "Option 5", video: "5.mp4" },
      { title: "Option 6", video: "6.mp4" },
      { title: "Option 7", video: "7.mp4" }
    ]
  }
]

// 0: Joan Punyet Miro
// 1: Rosa Maria Malet
// 2: Joan Baixas
// 3: Isabel Steva Colita
export const DATA = data[0];